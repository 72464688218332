import { branchHttp } from '../branch-http'
import { http } from '../http'
import {
  CreateDirectAccount,
  CreateUserPayload,
  CreateUserResponse,
  CreateWalletPayload,
  CreateWalletResponse,
  DirectDepositAuthorizationPayload,
  LinkAccountPayload,
  LinkAccountResponse,
} from './types'

export const createWallet = ({
  version = 6,
  ...requestBody
}: CreateWalletPayload) =>
  branchHttp.post<CreateWalletResponse>(
    '/p1/wallet/account/creation',
    requestBody,
    {
      params: { version, web_onboarding: true },
    }
  )

export const createUser = (payload: CreateUserPayload) =>
  http.post<CreateUserResponse>('/v1/accounts/branch/users', payload)

export const linkAccount = (payload: LinkAccountPayload) =>
  http.put<LinkAccountResponse>('/v1/accounts/branch/users/link', payload)

export const authorizeDirectDeposit = ({
  organization_id,
  worker_id,
  ...restPayload
}: DirectDepositAuthorizationPayload) =>
  branchHttp.post(
    `/p2/organizations/${organization_id}/workers/${worker_id}/direct_deposit_forms`,
    {
      ...restPayload,
      deposit_percent: 100,
    }
  )

export const createDirectAccount = (payload: CreateDirectAccount) =>
  http.post('/v1/accounts/direct/users', payload)
