import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  directTermsQueryOptions,
  userTermsQueryOptions,
} from '@/queryOptions/terms'

import { UseMappedDirectTermsProps } from './types'

export const useMappedUserTerms = () => {
  return useSuspenseQuery({
    ...userTermsQueryOptions,
    select: data => data.map(({ name, version }) => ({ name, version })),
  })
}

export const useMappedDirectTerms = (props?: UseMappedDirectTermsProps) => {
  return useQuery({
    ...directTermsQueryOptions,
    select: data => data.map(({ name, version }) => ({ name, version })),
    ...props,
  })
}
