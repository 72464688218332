import { fallback, zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { VerificationTypes } from '@/api/workers'

const schema = z.object({
  verificationType: z.nativeEnum(VerificationTypes),
  verificationStep: fallback(
    z.enum(['login', 'phoneVerification', 'branchDirectVerification']),
    'login'
  ).default('login'),
})

export const verifyOTPSearchSchema = zodValidator(schema)
export type VerifyOTPSearchSchema = z.infer<typeof schema>
