import { branchHttp } from '../branch-http'
import { http } from '../http'
import { TermsResponse } from './types'

export const getUserTerms = () =>
  http.get<TermsResponse>(`/v1/accounts/branch-user-terms`)

export const getEssentialTerms = () =>
  branchHttp.get<TermsResponse>(`/p2/accounts/terms/paycard/post_kyc`)

export const getDirectTerms = () =>
  http.get<TermsResponse>(`/v1/accounts/branch-direct-terms`)
