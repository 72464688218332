import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_initialized/verifyAccountInfo')({
  beforeLoad: ({ context: { createWalletFormStore } }) => {
    const dob = createWalletFormStore.getState().date_of_birth
    const address = createWalletFormStore.getState().address
    if (!dob) {
      redirect({ to: '/dateOfBirth', search: true, throw: true })
    } else if (!address?.streetLine) {
      redirect({ to: '/address/addressLookup', search: true, throw: true })
    }
  },
})
