import { Alert, Box, IconButton, Typography } from '@branch-messenger/willow-ui'
import styled, { css } from 'styled-components'

import { mobileStyles } from '../sharedStyles'

export const StyledContentCard = styled(Box).attrs({
  $flex: 1,
  $fullWidth: true,
  $display: 'flex',
  $direction: 'column',
  $align: 'stretch',
  $p: 9,
})`
  overflow: hidden;
  max-width: 608px;
  max-height: 728px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.radii.xl};

  box-shadow:
    0px 8px 8px -4px #35344408,
    0px 4px 24px -4px #35344414;

  ${mobileStyles(css`
    box-shadow: none;
    border-radius: 0;
    max-height: unset;
    padding: 0;
  `)}
`

export const StyledContentCardHeading = styled(Typography).attrs({
  $size: '3xl',
  $bold: true,
})`
  line-height: 1.1;
`

export const StyledContentCardBody = styled(Box).attrs({
  $flex: 1,
  $display: 'flex',
  $direction: 'column',
  $gap: 6,
  $align: 'stretch',
  $p: 3, //padding so input shadows don't get cut off by overflow hidden, also gives space between scrollbar and content
})`
  overflow-y: auto;
  scrollbar-color: ${({ theme }) => theme.colors.gray} transparent;
  scrollbar-width: thin;
  ${mobileStyles(css`
    padding: ${({ theme }) => theme.space[4]};
  `)}
`

export const StyledContentCardFooterContainer = styled(Box)`
  overflow-x: clip;
`

export const StyledContentCardFooter = styled(Box).attrs<{
  $showShadow?: boolean
}>({
  $p: 3, //padding so input shadows don't get cut off by overflow hidden
  $pt: 4,
  $display: 'flex',
  $direction: 'column',
  $gap: 6,
  $align: 'stretch',
})`
  ${({ $showShadow }) =>
    $showShadow &&
    css`
      box-shadow: 0px -4px 6px -2px #3534443b;
    `}

  ${mobileStyles(css`
    padding: ${({ theme }) => theme.space[4]};
  `)}
`

export const StyledBackButtonContainer = styled(Box).attrs({
  $px: 3,
  $pb: 4,
  $display: 'grid',
})`
  grid-template-columns: repeat(3, 1fr);
  & + ${StyledContentCardBody} {
    padding-top: 0;
  }
  ${mobileStyles(css`
    padding-top: ${({ theme }) => theme.space[4]};
  `)}
`

export const StyledBackButton = styled(IconButton).attrs({
  type: 'button',
  showBackground: false,
  showBackgroundOnHover: false,
})`
  padding: 0;
`
export const StyledContentCardFooterAlert = styled(Alert).attrs({
  $type: 'secondary',
  $rounded: true,
})`
  padding: ${({ theme }) => theme.space[4]};
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
`
