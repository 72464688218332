import { queryOptions } from '@tanstack/react-query'

import { getDirectTerms, getEssentialTerms, getUserTerms } from '@/api/terms'

export const USER_TERMS_QUERY_KEY = 'USER_TERMS_QUERY_KEY'
export const userTermsQueryOptions = queryOptions({
  queryKey: [USER_TERMS_QUERY_KEY],
  queryFn: async () => {
    const response = await getUserTerms()
    return response.data
  },
  staleTime: Infinity,
  gcTime: Infinity,
})

export const ESSENTIAL_TERMS_QUERY_KEY = 'ESSENTIAL_TERMS_QUERY_KEY'
export const essentialTermsQueryOptions = queryOptions({
  queryKey: [ESSENTIAL_TERMS_QUERY_KEY],
  queryFn: async () => {
    const response = await getEssentialTerms()
    return response.data
  },
  staleTime: Infinity,
  gcTime: Infinity,
})

export const DIRECT_TERMS_QUERY_KEY = 'DIRECT_TERMS_QUERY_KEY'
export const directTermsQueryOptions = queryOptions({
  queryKey: [DIRECT_TERMS_QUERY_KEY],
  queryFn: async () => {
    const response = await getDirectTerms()
    return response.data
  },
  staleTime: Infinity,
  gcTime: Infinity,
})
