import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'

import { AlternateErrorResponse } from '@/api/types'
import {
  getWorkerId,
  GetWorkerIdParams,
  sendOTP,
  sendOTPForNewVerificationMethod,
  SendOTPForNewVerificationMethodPayload,
  SendOTPPayload,
  verifyOTP,
  verifyOTPForNewVerificationMethod,
  VerifyOTPForNewVerificationMethodPayload,
  VerifyOTPPayload,
  WorkerOTPInfo,
} from '@/api/workers'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { handleHttpException } from '@/lib/httpExceptionHandler'
import {
  getWorkerOrgsQueryOptions,
  getWorkerOtpInfoQueryOptions,
  workerInfoQueryOptions,
} from '@/queryOptions/workers'
import { useLoginOTPSuccess } from '@/routeComponents/verifyOTP/hooks/useLoginOTPSuccess'
import { useNewVerificationMethodSuccess } from '@/routeComponents/verifyOTP/hooks/useNewVerificationMethodSuccess'
import { useAlertStore } from '@/store/alert'
import { Alert } from '@/store/alert.types'
import { useCreateAccountFormState } from '@/store/createAccountForm'

import { UseSendOTPForNewVerificationMethodProps } from './types'

export const useWorkerOTPInfo = <TData = WorkerOTPInfo>(
  select?: (data: WorkerOTPInfo) => TData
) => {
  const { worker_id } = useSearch({ strict: false })
  return useSuspenseQuery({
    ...getWorkerOtpInfoQueryOptions({ worker_id: worker_id! }),
    select,
  })
}

export const useWorkerOrgs = () => {
  const phone = useCreateAccountFormState(state => state.phone)
  return useSuspenseQuery(getWorkerOrgsQueryOptions({ phone: phone! }))
}

export const useWorkerInfo = () => {
  return useSuspenseQuery(workerInfoQueryOptions)
}

export const useSendOTPForExistingVerificationMethod = () => {
  const showAlert = useAlertStore(state => state.showAlert)
  const navigate = useAppNavigate()
  return useMutation({
    mutationFn: (payload: SendOTPPayload) => sendOTP(payload),
    onSuccess: (_, { type }) => {
      return navigate({
        to: '/verifyOTP',
        search: {
          verificationType: type,
          verificationStep: 'login',
        },
      })
    },
    onError: () => {
      showAlert({
        title: 'Failed to send OTP.',
        description: 'There was an issue sending OTP. Please try again later.',
        hideCancel: true,
      })
    },
  })
}

export const useVerifyOTPForExistingVerificationMethod = () => {
  const showAlert = useAlertStore(state => state.showAlert)
  const handleLoginOTPSuccess = useLoginOTPSuccess()

  return useMutation({
    mutationFn: (payload: VerifyOTPPayload) => verifyOTP(payload),
    onError: () => {
      showAlert({
        title: 'Invalid code.',
        description:
          "Please double check that you've correctly entered the 6 digit code that was sent to you and try again.",
        hideCancel: true,
      })
    },
    onSuccess: () => {
      return handleLoginOTPSuccess()
    },
  })
}

export const useSendOTPForNewVerificationMethod = (
  props?: UseSendOTPForNewVerificationMethodProps
) => {
  const showAlert = useAlertStore(state => state.showAlert)
  const navigate = useAppNavigate()
  const { verificationStep = 'phoneVerification' } = props || {}
  return useMutation({
    mutationFn: (payload: SendOTPForNewVerificationMethodPayload) =>
      sendOTPForNewVerificationMethod(payload),
    onSuccess: (_, { channel }) => {
      return navigate({
        to: '/verifyOTP',
        search: {
          verificationType: channel,
          verificationStep,
        },
      })
    },
    onError: () => {
      showAlert({
        title: 'Failed to send OTP.',
        description: 'There was an issue sending OTP. Please try again later.',
        hideCancel: true,
      })
    },
  })
}

export const useVerifyOTPForNewVerificationMethod = () => {
  const showAlert = useAlertStore(state => state.showAlert)
  const handleNewVerificationMethodSuccess = useNewVerificationMethodSuccess()

  return useMutation({
    mutationFn: (payload: VerifyOTPForNewVerificationMethodPayload) =>
      verifyOTPForNewVerificationMethod(payload),
    onError: () => {
      showAlert({
        title: 'Invalid code.',
        description:
          "Please double check that you've correctly entered the 6 digit code that was sent to you and try again.",
        hideCancel: true,
      })
    },
    onSuccess: async () => {
      try {
        await handleNewVerificationMethodSuccess()
      } catch (error) {
        //do nothing, this is so the error will be handled by useNewVerificationMethodSuccess hook, instead of the mutation handler above
      }
    },
  })
}

export const useLookupWorker = () => {
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationFn: (payload: GetWorkerIdParams) => getWorkerId(payload),
    onError: error => {
      const genericAlertInfo: Alert = {
        title: 'Failed to lookup worker.',
        description:
          'There was an issue looking up the worker. Please try again later.',
        hideCancel: true,
      }
      void handleHttpException<AlternateErrorResponse>(error, {
        onHttpError: e => {
          switch (e.response?.data?.status) {
            case 404:
              showAlert({
                title: 'Info not found.',
                description:
                  'Please try again or contact your organization for assistance.',
                hideCancel: true,
              })
              break
            // TODO: Rate limit error handling once OPS adds rate limiting https://branchmessenger.atlassian.net/browse/PAYENT-19079
            case 401:
              showAlert({
                title: e.response?.data?.error || genericAlertInfo.title,
                description:
                  e.response?.data?.message || genericAlertInfo.description,
                hideCancel: true,
              })
              break
            default:
              showAlert(genericAlertInfo)
          }
        },
        onOtherError: () => {
          showAlert(genericAlertInfo)
        },
      })
    },
  })
}
