import { isPossiblePhoneNumber } from '@branch-messenger/willow-ui'
import * as yup from 'yup'

export const workerLookupFormSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .test({
      name: 'Phone is valid',
      message: 'Please enter a valid phone number',
      test: value => (value ? isPossiblePhoneNumber(value) : false),
    })
    .required(),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email field is required'),
})

export type WorkerLookupFormSchema = yup.InferType<
  typeof workerLookupFormSchema
>
