import { Box } from '@branch-messenger/willow-ui'
import { ErrorComponentProps, SearchParamError } from '@tanstack/react-router'
import { FC } from 'react'

import Alert from '@/assets/illustrations/alert-illustration.svg?react'
import { RouteError } from '@/errors/RouteError'

import { ContentCard, ContentCardBody, ContentCardHeading } from '.'

export const ContentCardError: FC<ErrorComponentProps> = ({ error }) => {
  const isSearchParamError = error instanceof SearchParamError
  const isRouteError = error instanceof RouteError

  let message = 'An error occurred. Please try again later.'

  if (isSearchParamError) {
    message = 'Invalid search parameters.'
  } else if (isRouteError) {
    message = error.message
  }

  return (
    <ContentCard>
      <ContentCardBody>
        <Box $display="flex" $justify="center">
          <Alert />
        </Box>
        <ContentCardHeading subtitle={message}>
          Ooops! Something went wrong.
        </ContentCardHeading>
      </ContentCardBody>
    </ContentCard>
  )
}
