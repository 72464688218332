import { useSearch } from '@tanstack/react-router'

import {
  useCreateDirectAccountMutation,
  useCreateUserMutation,
} from '@/queries/account'
import {
  useMappedDirectTerms,
  useMappedUserTerms,
} from '@/selectors/terms/terms'
import { useCreateAccountFormState } from '@/store/createAccountForm'

export const useNewVerificationMethodSuccess = () => {
  const { verificationStep } = useSearch({ from: '/_initialized/verifyOTP' })
  const isBranchDirectVerificationStep =
    verificationStep === 'branchDirectVerification'
  const { data: mappedTerms } = useMappedUserTerms()
  const { data: mappedDirectTerms = [] } = useMappedDirectTerms({
    enabled: isBranchDirectVerificationStep,
  })
  const { mutateAsync: createAccount } = useCreateUserMutation()
  const { mutateAsync: createDirectAccount } = useCreateDirectAccountMutation()

  const phone = useCreateAccountFormState(state => state.phone)
  const email = useCreateAccountFormState(state => state.email)
  const password = useCreateAccountFormState(state => state.password)
  const confirm_password = useCreateAccountFormState(
    state => state.confirm_password
  )

  const handlePhoneVerificationOTPSuccess = () => {
    return createAccount({
      phone: phone!,
      terms: mappedTerms,
    })
  }

  const handleDirectOTPVerificationSuccess = () => {
    //TODO: need correct payload for direct account creation https://branchmessenger.atlassian.net/browse/PAYENT-19373
    return createDirectAccount({
      confirm_password: confirm_password!,
      email: email!,
      password: password!,
      terms: [...mappedDirectTerms, ...mappedTerms],
    })
  }

  return isBranchDirectVerificationStep
    ? handleDirectOTPVerificationSuccess
    : handlePhoneVerificationOTPSuccess
}
