import qs from 'qs'

import { branchHttp } from '../branch-http'
import {
  ConfirmPasscodePayload,
  CreatePasscodePayload,
  GetExchangeTokenResponse,
  GetPasscodeResponse,
} from './types'

export const getPasscode = () =>
  branchHttp.get<GetPasscodeResponse>('/auth/access_code')

export const createPasscode = (req: CreatePasscodePayload) =>
  branchHttp.post('/auth/access_code', req)

export const confirmPasscode = (req: ConfirmPasscodePayload) =>
  branchHttp.post('/auth/access_code/token', req)

export const getExchangeToken = (token: string) =>
  branchHttp.get<GetExchangeTokenResponse>(`/auth/onboarding/exchange`, {
    params: { token },
    paramsSerializer: params => qs.stringify(params, { encode: false }),
  })
